<template>
  <el-dialog
      :title="title"
      :visible.sync="visible"
      width="800px" append-to-body>
    <div v-if="data">
      <el-table :data="data.content" stripe tooltip-effect="light">
        <el-table-column type="index" label="序号" :index="index">
        </el-table-column>
        <el-table-column prop="zhmc" label="会展名称" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/zhanhui/details',query:{id:row.id}}).href">{{ row.zhmc }}</a>
        </el-table-column>
        <el-table-column prop="zhmj" label="会展面积（㎡）" width="120" show-overflow-tooltip></el-table-column>
        <el-table-column label="展期" width="210">
          <span slot-scope="{row}">{{ row.jbkssj }} ~ {{ row.jbjssj }}</span>
        </el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="visible = false">关 闭</el-button>
  </span>
  </el-dialog>
</template>

<script>
import api from "../api";

export default {
  name: "ZhanhuiListDialog",
  data() {
    return {
      visible: false,
      params:null,
      data: null,
      title:null
    }
  },
  methods: {
    async open(title,params) {
      this.title=title
      this.params=params
      this.visible=true
      await this.loadData(1)
    },
    async loadData(page){
      const params=Object.assign({},{page:page-1,size:10,sort:'jbkssj,DESC'},this.params)
      this.data=(await api.listZhanhui(params)).data
    },
    index(index){
      return this.data.number*this.data.size+index+1
    }
  }
}
</script>

<style scoped>

</style>
