<template>
  <div class="tag-select">
    <div class="tag-select-item" v-for="t in cachedTags" :key="t.name" :class="{selected:t.selected}" @click="toggleTag(t)">{{t.name}}</div>
  </div>
</template>

<script>
export default {
  name: "TagSelect",
  model: {
    event: 'change'
  },
  props:{
    tags:Array,
    value:{
      type:[String,Array]
    },
    multiple:Boolean
  },
  data(){
    return {
      cachedValue:null,
      cachedTags:[]
    }
  },
  created() {
    this.init()
  },
  watch:{
    tags(){
      this.init()
    },
    value(val){
      if(this.cachedValue===val){
        return
      }
      this.updateCachedValue()
    }
  },
  methods:{
    init(){
      if(!this.tags||this.tags.length===0){
        this.cachedTags=[]
      }else{
        this.cachedTags=this.tags.map(t=>{
          return {
            name:t,
            selected:false
          }
        })
      }
      this.updateCachedValue()
    },
    updateCachedValue(){
      this.cachedTags.forEach(t=>t.selected=false)
      if(this.multiple){
        if(this.value){
          for(const v of this.value){
            const tag = this.cachedTags.find(t=>t.name===v)
            if(tag){
              tag.selected=true
            }
          }
        }
      }else if(this.value){
        const tag = this.cachedTags.find(t=>t.name===this.value)
        if(tag){
          tag.selected=true
        }
      }
      this.cachedValue=this.value
    },
    toggleTag(tag){
      tag.selected=!tag.selected
      if(tag.selected&&!this.multiple){
        this.cachedTags.forEach(t=>{
          if(t!==tag){
            t.selected=false
          }
        })
      }
      const selectedTags=this.cachedTags.filter(t=>t.selected).map(t=>t.name)
      if(this.multiple){
        this.$emit('change',selectedTags)
      }else{
        this.$emit('change',selectedTags.length>0?selectedTags[0]:null)
      }
    }
  }
}
</script>

<style scoped>

</style>
