<template>
  <block title="周边场馆">
    <div v-if="data">
      <el-table :data="data.content" :default-sort = "sort"
                @sort-change="onSortChange" tooltip-effect="light">
        <el-table-column prop="mc" label="场馆" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/changguan/details',query:{id:row.id}}).href">{{row.mc}}</a>
        </el-table-column>
        <el-table-column prop="distance" label="与酒店距离（㎡）" width="160" sortable="custom"></el-table-column>
        <el-table-column prop="zhanhuiCount" label="办展次数" width="110" sortable="custom">
          <a class="link" slot-scope="{row}" @click="showZhanhuiList(row)">{{row.zhanhuiCount}}</a>
        </el-table-column>
        <el-table-column prop="hy" label="行业" width="250">
          <ellipsis-text slot-scope="{row}" :text="row.hy"></ellipsis-text>
        </el-table-column>
        <el-table-column label="最新会展情况" align="center">
          <el-table-column prop="latestZhanhui.zhmc" label="会展名称" show-overflow-tooltip>
            <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/zhanhui/details',query:{id:row.latestZhanhui.id}}).href">{{row.latestZhanhui.zhmc}}</a>
          </el-table-column>
          <el-table-column label="展期" width="210">
            <span slot-scope="{row}">{{row.latestZhanhui.jbkssj}} ~ {{row.latestZhanhui.jbjssj}}</span>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
    <zhanhui-list-dialog ref="zhanhuiListDialog"></zhanhui-list-dialog>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";
import moment from "moment";
import ZhanhuiListDialog from "./ZhanhuiListDialog";
import EllipsisText from "./EllipsisText";

export default {
  name: "HxeNearestChangguanZhanhuiInfoBlock",
  components: {Block,ZhanhuiListDialog,EllipsisText},
  props:{
    changguan:Object
  },
  data() {
    return {
      sort:{prop: 'distance', order: 'descending'},
      data: null
    }
  },
  watch:{
    area(){
      this.init()
    },
    dateRange(){
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.loadData(1)
    },
    async loadData(page){
      const params={
        geoLon:this.changguan.location2.lon,
        geoLat:this.changguan.location2.lat,
        geoDistance:1000,
        page:page-1,
        size:10,
        sort:this.sort.prop+","+(this.sort.order==='descending'?'DESC':'ASC')
      }
      this.data=(await api.listChangguanZhanhuiInfo1(params)).data
    },
    onSortChange({prop, order}){
      this.sort={prop, order}
      this.loadData(1)
    },
    showZhanhuiList(row){
      const params={
        jbcg:row.mc,
        startDate:this.dateRange&&this.dateRange.length>0?moment(this.dateRange[0]).format('YYYY-MM-DD'):null,
        endDate:this.dateRange&&this.dateRange.length>0?moment(this.dateRange[1]).format('YYYY-MM-DD'):null,
      }
      const title=[row.mc,'会展情况']
      if(this.dateRange&&this.dateRange.length>0){
        title.push('（',moment(this.dateRange[0]).format('YYYY-MM-DD'),' ~ ',
            moment(this.dateRange[1]).format('YYYY-MM-DD'),'）')
      }
      this.$refs.zhanhuiListDialog.open(title.join(''),params)
    }
  }
}
</script>

<style scoped>

</style>
