<template>
  <div class="collapse">
    <div class="collapse-body" :style="bodyStyle">
      <slot></slot>
    </div>
    <div class="collapse-bar" :style="{height:collapsedHeight}">
      <el-link v-if="collapsed" @click="open" type="primary">{{openTitle}}<i class="el-icon-arrow-down el-icon--right"></i></el-link>
      <el-link v-else @click="close" type="primary">{{closeTitle}}<i class="el-icon-arrow-up el-icon--right"></i></el-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Collapse",
  props:{
    collapsedHeight:{
      type:String,
      required:true
    },
    openTitle:{
      type:String,
      default:'展开'
    },
    closeTitle:{
      type:String,
      default:'收起'
    },
  },
  computed:{
    bodyStyle(){
      return this.collapsed?{height:this.collapsedHeight,overflow:'hidden'}:{}
    }
  },
  data(){
    return {
      collapsed:true
    }
  },
  methods:{
    open(){
      this.collapsed=false
    },
    close(){
      this.collapsed=true
    }
  }
}
</script>

<style scoped>

</style>
