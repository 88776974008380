<template>
  <el-tooltip effect="light">
    <div class="ellipsis-text-content">{{text}}</div>
    <div class="ellipsis-text-tooltip" slot="content" :style="{'max-width': tooltipWidth}">{{text}}</div>
  </el-tooltip>
</template>

<script>
export default {
  name: "EllipsisText",
  props:{
    text:String,
    tooltipWidth:{
      type:String,
      default:'40vw'
    }
  }
}
</script>

<style scoped>

</style>
