<template>
  <div class="page-content" v-if="data">
    <div class="page-tips">数据来源于会小二，已接入北京及上海两个城市信息</div>
    <div class="search-form" style="margin-top: 10px;">
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">区域：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <div class="area-provinces-select">
                <div class="area-provinces-select-item" v-for="i in areaAndProvinces" :key="i.area" :style="{width:i.width}">
                  <div class="area-provinces-select-area">{{i.area}}：</div>
                  <tag-select class="area-provinces-select-provinces" :tags="i.provinces" v-model="i.selected" multiple></tag-select>
                </div>
              </div>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">场地类型：</div>
          <div class="search-form-field-content">
            <tag-select :tags="types" v-model="searchForm.type" multiple></tag-select>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">容纳人数：</div>
          <div class="search-form-field-content">
            <tag-select :tags="capacityList" v-model="searchForm.capacity"></tag-select>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会场面积：</div>
          <div class="search-form-field-content">
            <tag-select :tags="spaceList" v-model="searchForm.space"></tag-select>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">酒店：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.nameLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">场馆周边：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.nearName" style="width: 200px;" size="mini"></el-input>
            <tag-select :tags="distanceList" v-model="searchForm.nearDistance" style="margin-left: 10px"></tag-select>
          </div>
        </div>
      </div>
      <div class="search-form-bottom">
        <el-button type="primary" @click="search(false)">检索</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>
    <div style="margin-top: 20px;">
      <div class="hxe-changguan-card" v-for="item in data.content" :key="item.data.id">
<!--        <el-image class="hxe-changguan-card-logo" fit="fill" :src="item.data.files && item.data.files.length>0?$api.getAttachmentUrl(item.data.files[0]):''" lazy></el-image>-->
        <el-image class="hxe-changguan-card-logo" fit="fill" :src="item.data.image_list && item.data.image_list.length>0?item.data.image_list[0]:''" lazy>
          <div slot="error" class="image-slot">
            <img :src="require('../assets/images/no_logo.png')" alt="" style="width: 200px;height: 267px;" />
          </div>
        </el-image>
        <div class="hxe-changguan-card-right">
          <div class="hxe-changguan-card-info">
            <div class="hxe-changguan-card-title">
              <a class="hxe-changguan-card-name"
                 :href="$router.resolve({path:'/hxe-changguan/details',query:{id:item.data.id}}).href">{{item.data.name}}</a>
              <span class="hxe-changguan-card-type">[{{item.data.type}}]</span>
            </div>
            <div class="hxe-changguan-card-subtitle">
              <span>{{item.data.city_name}} · {{item.data.county_name}} · 地址：{{item.data.address}}</span>
            </div>
            <el-row>
              <el-col :span="8" class="hxe-changguan-card-field">
                <span class="hxe-changguan-card-field-label">会场面积: </span>
                <span class="hxe-changguan-card-field-value">{{item.data.conf_min_space}}-{{item.data.conf_max_space}}m²</span>
              </el-col>
              <el-col :span="8" class="hxe-changguan-card-field">
                <span class="hxe-changguan-card-field-label">会场数量: </span>
                <span class="hxe-changguan-card-field-value">{{item.data.conf_total}}间</span>
              </el-col>
              <el-col :span="8" class="hxe-changguan-card-field">
                <span class="hxe-changguan-card-field-label">会场价格: </span>
                <span class="hxe-changguan-card-field-value point">￥{{item.data.conf_min_price>0?item.data.conf_min_price:'-'}}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8" class="hxe-changguan-card-field">
                <span class="hxe-changguan-card-field-label">容纳人数: </span>
                <span class="hxe-changguan-card-field-value">{{item.data.conf_min_capacity}}-{{item.data.conf_max_capacity}}人</span>
              </el-col>
              <el-col :span="8" class="hxe-changguan-card-field">
                <span class="hxe-changguan-card-field-label">客房数量: </span>
                <span class="hxe-changguan-card-field-value">{{item.data.room_total>0?item.data.room_total:'-'}}间</span>
              </el-col>
              <el-col :span="8" class="hxe-changguan-card-field">
                <span class="hxe-changguan-card-field-label">客房参考价: </span>
                <span class="hxe-changguan-card-field-value point">￥{{item.data.room_price>0?item.data.room_price:'-'}}</span>
              </el-col>
            </el-row>
            <div class="hxe-changguan-card-feature">
              <span class="hxe-changguan-card-feature-tag" v-for="f in item.data.feature" :key="f">{{f}}</span>
            </div>
          </div>
          <div class="hxe-changguan-card-imgs">
<!--            <el-image v-for="i in item.data.files" :key="i" :src="$api.getAttachmentUrl(i)" lazy fit="fill"></el-image>-->
            <el-image v-for="i in item.data.image_list" :key="i" :src="i" lazy fit="fill"></el-image>
          </div>
        </div>
      </div>
      <el-pagination layout="prev, pager, next" background :page-size="pageSize"
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import TagSelect from "./TagSelect";
import Collapse from "./Collapse";

export default {
  name: "HxeChangguanList",
  components:{TagSelect,Collapse},
  directives: {
    'attachment-attr': AttachmentAttrDirective,
  },
  data(){
    return {
      types:["五星酒店","四星酒店","三星酒店","三星以下","餐厅","艺术中心","会所","咖啡厅","度假村","剧院","酒吧","培训中心","会展中心","体育馆","其他"],
      areaAndProvinces:[
        {
          area:"华东",
          provinces:["山东", "江苏", "上海", "浙江", "福建", "安徽", "江西"],
          selected:[],
          width:'40%',
        },
        {
          area:"华南",
          provinces:["广东", "广西", "海南"],
          selected:[],
          width:'30%',
        },
        {
          area:"西南",
          provinces:["四川", "云南", "重庆", "贵州", "西藏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华北",
          provinces:["河北", "北京", "天津", "内蒙古", "山西"],
          selected:[],
          width:'40%',
        },
        {
          area:"西北",
          provinces:["陕西", "新疆", "甘肃", "青海", "宁夏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华中",
          provinces:["湖南", "湖北", "河南"],
          selected:[],
          width:'30%',
        },
        {
          area:"东北",
          provinces:["辽宁", "吉林", "黑龙江"],
          selected:[],
          width:'40%',
        }
      ],
      capacityList:['10-30人','30-60人','60-100人','100-200人','200-300人','300-500人','500-1000人','1000+人'],
      spaceList:['0-100㎡','100-200㎡','200-300㎡','300-400㎡','400-500㎡','500-600㎡','600-800㎡','800-1000㎡','1000-1500㎡','1500+㎡'],
      distanceList:['1公里内','3公里内','5公里内'],
      searchForm:{
        type:null,
        capacity:null,
        space:null,
        nameLike:null,
        nearName:null,
        nearDistance:null,
      },
      searchParams:{
        type:null,
        province:null,
        minCapacity:null,
        maxCapacity:null,
        minSpace:undefined,
        maxSpace:undefined,
        nameLike:null,
        geoLon:null,
        geoLat:null,
        geoDistance:null,
        excludeId:null
      },
      pageSize:10,
      data:null
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      await this.search(true)
    },
    async search(init){
      this.searchParams.type=this.searchForm.type
      const province=this.areaAndProvinces.reduce((acc,v)=>{
        acc.push(...v.selected)
        return acc
      },[])
      if(province.length===0){
        this.searchParams.province=null
      }else{
        this.searchParams.province=province.map(p=>{
          if(p==='北京'||p==='重庆'||p==='上海'||p==='天津'){
            return p+'市'
          }else if(p==='内蒙古'||p==='西藏'){
            return p+'自治区'
          }else if(p==='宁夏'){
            return p+'回族自治区'
          }else if(p==='广西'){
            return p+'壮族自治区'
          }else if(p==='新疆'){
            return p+'维吾尔自治区'
          }else{
            return p+'省'
          }
        })
      }
      if(this.searchForm.capacity){
        const idx=this.searchForm.capacity.indexOf('-')
        if(idx!==-1){
          const idx2=this.searchForm.capacity.indexOf('人')
          this.searchParams.minCapacity=parseInt(this.searchForm.capacity.substring(0,idx))
          this.searchParams.maxCapacity=parseInt(this.searchForm.capacity.substring(idx+1,idx2))
        }else{
          this.searchParams.minCapacity=1000
          this.searchParams.maxCapacity=null
        }
      }else{
        this.searchParams.minCapacity=null
        this.searchParams.maxCapacity=null
      }
      if(this.searchForm.space){
        const idx=this.searchForm.space.indexOf('-')
        if(idx!==-1){
          const idx2=this.searchForm.space.indexOf('㎡')
          this.searchParams.minSpace=parseInt(this.searchForm.space.substring(0,idx))
          this.searchParams.maxSpace=parseInt(this.searchForm.space.substring(idx+1,idx2))
        }else{
          this.searchParams.minSpace=1500
          this.searchParams.maxSpace=null
        }
      }else{
        this.searchParams.minSpace=null
        this.searchParams.maxSpace=null
      }
      this.searchParams.nameLike=this.searchForm.nameLike
      if(this.searchForm.nearName&&this.searchForm.nearDistance){
        const data=(await api.listHuixiaoerChangguanInit({name:this.searchForm.nearName,size:1,page:0})).data.content
        if(data.length>0){
          this.searchParams.geoLon=data[0].location2.lon
          this.searchParams.geoLat=data[0].location2.lat
          this.searchParams.excludeId=data[0].data.id
        }else{
          this.searchParams.geoLon=null
          this.searchParams.geoLat=null
          this.searchParams.excludeId=null
        }
        this.searchParams.geoDistance=[1000,3000,5000][this.distanceList.indexOf(this.searchForm.nearDistance)]
      }else{
        this.searchParams.geoLon=null
        this.searchParams.geoLat=null
        this.searchParams.excludeId=null
        this.searchParams.geoDistance=null
      }
      if(init){
        await this.loadData1(1)
      }else{
        await this.loadData(1)
      }
    },
    async reset(){
      this.searchForm.type=[]
      this.areaAndProvinces.forEach(i=>i.selected=[])
      this.searchForm.capacity=null
      this.searchForm.space=null
      this.searchForm.nameLike=null
      this.searchForm.nearName=null
      this.searchForm.nearDistance=null
    },
    async loadData(page){
      if(this.searchParams.geoDistance&&!this.searchParams.geoLon){
        this.data={
          totalElements:0,
          number:0,
          content:[]
        }
        return
      }
      const params={
        ...this.searchParams,
        page:page-1,
        size:this.pageSize
      }
      this.data=(await api.listHuixiaoerChangguan(params)).data
    },
    async loadData1(page){
      if(this.searchParams.geoDistance&&!this.searchParams.geoLon){
        this.data={
          totalElements:0,
          number:0,
          content:[]
        }
        return
      }
      const params={
        ...this.searchParams,
        page:page-1,
        size:this.pageSize
      }
      this.data=(await api.listHuixiaoerChangguanInit(params)).data
    }
  }
}
</script>

<style scoped>

</style>
